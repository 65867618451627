@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

#main {
      
  width: 100%;
  z-index: 1;
  font-family: 'Roboto', sans-serif;
  background-color: #F4F5FA;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    background-color: #ffffff;
  }
    #content{
      padding: 100px 140px 40px 140px;
      @media (max-width: 991px) {
      padding: 100px 0px 40px 0px;
      }
      #contentCaption{
        text-align: center;
        h1 {
          font-weight: bold;
        }
        span{
          color: #727272;
        }
      }
      #contentContainer{
        margin-top: 40px;
        background-color: #ffffff;
        padding: 40px;
        border-radius: 40px;
        display: flex;
        align-items: stretch;
        @media (max-width: 991px) {
          padding: 12px;
          margin-top: 20px;
        }
        // box-shadow: 0px 8px 24px 0px rgba(22, 22, 22, 0.15); 
        .containerItem:last-of-type{
          @media (max-width: 991px) {
            margin-top: 40px;

          }
        }
       .containerItem{
        #message{
          .lastTextField{ 
              .MuiInputLabel-root {
                /* Styles specific to the multiline text field */
                padding-left: 8px;
              }
              .MuiInputLabel-shrink {
                padding-left: 0; // Remove padding when the label is floating
              }
          }
          .MuiOutlinedInput-root {
            border-radius: 16px;
            @media (max-width: 991px) {
              min-height: 68px;
            }
      
            @media (max-height: 952px) {
              min-height: 56px;
            }
          }
          .MuiInputLabel-root {
              padding-left: 32px; 
              @media (max-width: 991px) {
                padding-top: 8px;
              }
              @media (max-height: 952px) {
                padding-top: 0;
            }
          }
          .MuiInputLabel-shrink {
            padding-left: 0; // Remove padding when the label is floating
            padding-top: 0;
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
          box-shadow: 0 0 0 1000px transparent inset !important;
          -webkit-text-fill-color: #000 !important; /* Ensure text color remains unchanged */
          transition: background-color 5000s ease-in-out 0s; /* Prevent background change */
          }
          .inputField{
            margin-bottom: 0px;
            
          }
          .proceedButton{
            padding: 16px 32px;
            border-radius: 16px;
            background-color: #0262AB;
            font-size: 16px;
            box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15); 
          }
        }
        #dividerCol{
          color: #727272;
          span{
            font-size: 12px;
          }
        }
        #contactUs{
          margin-top: 20px;
          .contactItem {
            display: flex;
            align-items: center;
            max-width: 100%;
            padding: 8px;
            word-break: break-word;
            .MuiAvatar-circular{
              background-color: #0262AB;
            }
            .contactInfo{
              margin-left: 12px;
              text-wrap:wrap;
              
              span {
                font-size: 12px;
                font-weight: bold;
                color: #727272;
              }
              h6{
                font-size: 16px;
                color:#0262AB;
                line-height: 1.5;
              }
            }
          }
        }
        .mapContainer {
          width: 100%;
          height: 300px;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          border-radius: 20px;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
       }
        
      }
      
    }
}