@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
#succesModal{
  #succesModalStyles{
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 480px;
  width: 100%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  text-align: center;
  padding: 40px;
  border-radius: 40px;
    @media (max-width: 991px) {
      max-width: 360px;
    }
  }
}
#signupPage {
    font-family: 'Roboto', sans-serif;
    background: #FEFEFE;
    position: relative; /* Ensure the container has a defined position */
    min-height: 100dvh; /* Ensure the container covers the entire viewport height */
   
    width: 100%;
    z-index: 1;
    @media (max-width: 991px) {
      // Styles for screens smaller than 767px
      min-height: none;
    }
   
#signupContainer { /* Ensure the container covers the entire viewport height */
    display: flex;
    padding: 60px 80px;
    flex-grow: 1;
    min-height: 100vh;
    @media (max-width: 991px) {
      // Styles for screens smaller than 767px
      justify-content: space-between;
      padding: 40px;
      padding-top: 100px;
    }
    @media (max-height: 767px) {
      // Styles for screens smaller than 767px
      padding: 20px;
      padding-top: 60px;
    }
   
    #signupForm{
      width: 100%;
      display: flex;
      flex-direction: column;
     
      @media (max-width: 767px) {
         // Add other styles here
    .MuiStepper-root{
     padding-top: 8px;
    }
        .MuiFormControl-root{
          margin-bottom: 0;
        }
        
      }
      
    
      img{
        @media (max-width: 991px) {
          height: 100px;
          margin-bottom: 100px;
          align-self: center;
        }
        @media (max-height: 952px) {
          height: 80px;
          margin-bottom: 20px;
        }
    }
    
    h1{
      font-size: clamp(20px, 4vw, 40px);
        margin-bottom: 20px;
    }
    p{
      font-size: clamp(12px, 2vw, 16px);
    }
    .validIcon{
      padding-left: 10px;
      color: #0262AB;
      height: 32px;
      width: 32px;
      
    }
    //MUI TextField
    .MuiOutlinedInput-root {
        border-radius: 16px;
        @media (max-width: 991px) {
          height: 68px;
        }

        @media (max-height: 952px) {
          height: 56px;
        }
      }
    .MuiInputLabel-root {
        padding-left: 32px; // Adjust padding to ensure label does not overlap with the search icon
        
        @media (max-width: 991px) {
          // Styles for screens smaller than 767px
          padding-top: 8px;
        }
        @media (max-height: 952px) {
          padding-top: 0;
        }
      }
    .MuiInputLabel-shrink {
        padding-left: 0; // Remove padding when the label is floating
      }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000 !important; /* Ensure text color remains unchanged */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background change */
    }
    .inputField{
      margin-bottom: 0px;
      
    }
    .proceedButton{
      padding: 16px 32px;
      border-radius: 16px;
      background-color: #0262AB;
      font-size: 16px;
      margin-top: 16px;
      box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15); 
    }
   
    #terms{
      display: flex;
      align-items: flex-start;
      .MuiFormControlLabel-root {
        align-items: flex-start;
        
        .MuiCheckbox-root {
            margin-top: 0;
        }

        .MuiFormControlLabel-label {
            padding-top: 8px; // Adjust the padding to align the label
        }
    }

    .MuiFormHelperText-root {
        margin-left: 0; // Adjust this if needed for alignment
    }
    }
    
    .flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      #loginButton{
        padding: 16px 8px;
        border-radius: 16px;
        background-color: #0262AB;
        font-size: 16px;
        margin-top: 32px;
        box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15); 
      }
    }

      #loginLink {
        margin-top: 40px;
        text-align: center;
        margin-bottom: 80px;
        #loginText{
            font-weight: bold;
            text-decoration: underline;
            color: #0262AB;
        }
      }

  }

  
  #illustrationContainer{
    background: linear-gradient(to bottom, #002195, #0262AB, #00A8FF);
    min-height: 100dvh; /* Ensure the container covers the entire viewport height */
    display: flex;
    padding: 60px 80px;
    #signupLogo{
      height: 56px;
      margin-bottom:40px;
  }
   
    
    
   
    .illustration{
        
        width: 100%;
        max-height: 340px; // Set max height to 360px
        object-fit: contain;
        margin-bottom: 28px;
    }

    h3{
      color: #ffffff;
      font-size: 28px;
      margin-bottom: 40px;
  }
  
    
  }
}
