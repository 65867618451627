.results-table {
    margin-top: 20px;
    thead {
        th {
            min-width: 120px;
            font-size: .75em;
        }
    }
    tbody {
        td {
            font-size: .9em;
        }
    }

}
.notes {
    margin-top: 40px;
    font-size: .85em;
    .warning {
        color:red;
        font-weight: bold;
    }
}
.molecule, .interactingMolecule {
    font-weight: bold;
    color: #0262AB;
}
.mimsclass {color: #0061AA;}

#NoInteractionAlert {
    margin-top: 10px; 
    border: salmon 1px solid;
    padding: 20px;
    display: grid;
    br {content: " "; display:block; margin-bottom: 12px;}
    i {margin-bottom: 25px;color:#0061AA; }
    p {margin-bottom: 0;}
}

#Interaction-Tabs {
    padding-left: 10px;
    margin-left: -10px;
    margin-right: -10px;    
    border-bottom: 1px solid gray;

    .nav-item{ padding-right: 2px;
        button { color: #0061AA; font-weight: bold; border-top: 1px solid gray; border-left: 1px solid gray; border-right: 1px solid gray; }
        [aria-selected="false"] {
          color: black; font-weight: normal; background-color: lightgray;
        }
        .disabled {
            color: gray;
            background-color: lightgray;
            border-bottom: 1px solid gray;
        }
    }
}