@font-face {
    font-family: InterRegular;
    src: url('../Content/fonts/Inter-Regular.ttf');
}

@font-face {
    font-family: InterMedium;
    src: url('../Content/fonts/Inter-Medium.ttf');
}

.paddtop {
    display: grid;
    justify-content: center;
    height: 96.5vh;
    display: flex;
    align-items: center;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    margin-top: 15px;
    margin-left: 0px;
    /*  padding-left: 15px;
    padding-right: 15px;*/
}

/* Override the default bootstrap behavior where horizontal description lists 
   will truncate terms that are too long to fit in the left column 
*/
.dl-horizontal dt {
    white-space: normal;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
    max-width: 280px;
}

.btn:focus,
.btn:active:focus,
.btn-link.nav-link:focus,
.form-control:focus,
.form-check-input:focus {
    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    /* margin-bottom: 60px;
    padding: 20px 20px 20px 20px; */
}

.scenariostyle {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 6px;

    height: 60vh;
    overflow: auto;
}

.css-wsp0cs-MultiValueGeneric {
    width: 100px !important;
}

.gPosition {
    width: 9%;
    text-align: end;
}

.mtop20 {
    margin-top: 15px;
}

.gButton {
    background-color: transparent;
    border: none;
}

.amplify-flex {
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: flex;
    /* all: unset; */
    list-style-type: none;
}

.boxStatus {
    padding: 8px 60px 8px 60px;
    border-radius: 5px;
}

.UnlikelyColor {
    background-color: chartreuse;
}

.d-flow {
    display: flow-root;
}

.mtop30 {
    margin-top: 30px;
}

.explanationTile {
    border: 2px solid #a09e9e;
    border-start-start-radius: 4px;
    border-start-end-radius: 4px;
    padding: 20px;
    margin-bottom: 0px;
    background-color: #f0f0f0;
}

.explanationBody {

    padding: 20px;
    border-right: 2px solid #a09e9e;
    border-bottom: 2px solid #a09e9e;
    border-left: 2px solid #a09e9e;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.image1 {
    /* image 1 */
    /*position: absolute;*/
    /* width: 365px; */
    height: 122px;
    /*left: calc(50% - 365px/2 - 5.5px);*/
    /*top: 54px;*/
    /*background: url(image.png);*/
}

.Drugs4dent {
    /* MIMS Drugs4dent® */
    position: absolute;
    width: 987px;
    height: 107px;
    left: 213px;
    top: 39px;
    font-family: 'Literata';
    font-style: normal;
    font-weight: 600;
    font-size: 65px;
    line-height: 97px;
    color: #FFFFFF;
}

.Rectangle {
    /* Rectangle 14 */
    position: absolute;
    width: 100%;
    height: 230px;
    /* left: 37px; */
    /*top: 31px;*/
    background: linear-gradient(180deg, #0061AA 0%, #00518D 67.71%, #002A49 100%);
    border-radius: 20px;

}

.LogoLocation {
    text-align: center;

}

.bg-dark {
    background: linear-gradient(180deg, #0061AA 0%, #00518D 67.71%, #002A49 100%);
    border-radius: 20px;
    height: 180px;
    display: grid;
    justify-content: center;
}

.subHeader {
    /* A tailored medicines resource for dental practitioners, powered by MIMS */
    /*position: relative;*/
    /*width: 629px;*/
    /*height: 23px;*/
    /*left: calc(50% - 629px/2 - 5.5px);*/
    /*top: 189px;*/
    /*font-family: 'Roboto';*/
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
    display: flex;
}

/* .sidev2 {
    display: flex;
    margin-bottom: 66px;
}*/
.RectangleLeft {
    /* Rectangle 7 */

    position: absolute;
    width: 79px;
    height: 66px;
    left: 37px;
    top: 267px;
    background: #FFFFFF;
    border: 5px solid #D9D9D9;
}

main {
    display: flex;
}

.sidenav {
    gap: 11px;
    display: grid;
    /*width: 557px;*/
    /*width: 5%;*/
    /* height: 100%;
    width: 100px;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 200px;
    padding-left: 10px;*/
}


.navIconBox {
    /* Rectangle 7 */

    position: relative;
    width: 79px;
    /*height: 66px;
    left: 10px;
    top: 20px;*/
    background: #FFFFFF;
    border: 5px solid #D9D9D9;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 70px;
}

.navImamge {
    position: absolute;
    width: 53px;
    height: 53px;
    /* left: 51px;
    top: 273px;*/
}

.leftbox {
    width: 100%;
    background-color: #D9D9D9;
    border-radius: 5px;
    /*font-family: 'Lohit Tamil';*/
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
    justify-content: flex-start;
    padding-left: 10px;
    padding: 10px 15px 10px 15px;
    margin-bottom: 11px;
    height: 70px;
}

.countSum {
    /*width: 48%;*/
    text-align: end;
    color: #0061AA;
    font-weight: 500;
}

.boxSummaryIcon {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    margin-bottom: 6px;
    padding: 4px 0px 4px 2px;
    width: 520px;
    display: flex;
    /*text-align: center;*/
    align-items: center;
}

.imageHomeIcon {
    /*border: 1px solid;
    height: 118px;
    width: 186px;*/
    height: 54px;
    border-radius: 5px;
}

.leftmar {
    padding-left: calc(var(--bs-gutter-x)* 2.1);
}

.bstatus {
    height: 40px !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
}

.op {
    background: rgba(0, 0, 102, 0.286275);
    width: 101px;
    height: 29px;
    margin-left: 22px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: aliceblue;
    opacity: 0.6;
}

.minsUpdateBox {
    padding: 10px 10px 10px 40px;
    font-size: 22px;
}

.searchT {
    border: 1px solid gainsboro;
    padding: 10px 10px 10px 20px;
}

.searchT2 {
    border: 1px solid gainsboro;
    padding: 10px 10px 10px 20px;
    background-color: #D9D9D9;
}

.pmBox {
    background-color: white;
    border-radius: 8px;
    padding: 14px;
    display: grid;
    gap: 40px;
}

.mb3 {
    margin-bottom: -1rem;
}

.SeachModal {

    padding: 50px;

}

input,
select,
textarea {
    max-width: 100% !important;
}

.w-90 {
    width: 90%;
}

.f1 {
    font-size: 22px;
}

.evidence div {
    background-color: #ddd;
    border-radius: 5px;
    display: grid;
    margin-right: 20px;
    min-width: 20%;
    padding: 10px 20px;
    text-align: center;
}

.evidence .isActve {
    background-color: #2d6da6;
    color: #fff;
}

.recommendation {
    border-radius: 10px;
    display: grid;
    font-size: .8em;
    margin: auto 20px auto 0;
    padding: 8px 10px;
    text-align: center;
    width: 200px;
}

.ml-2 {
    margin-left: 1.5rem;
}

.backgr {
    background: #f6f7fb;
    padding: 35px;
}

.white-box {
    background-color: #fff;
    border-radius: 15pt;
    box-sizing: border-box;
    padding: 20px 15px;
}

.recommendation1 {
    background-color: lightskyblue;
    color: #fff;

}

.likelihood {
    border-radius: 10px;
    color: #fff;
    display: grid;
    font-size: .8em;
    font-weight: 500;
    min-width: 100px;
    padding: 8px 0;
    text-align: center;
    width: 100%;
}

.likelihood3 {
    background-color: #53b353;
}

.severity {
    border-radius: 10px;
    color: #fff;
    display: grid;
    font-size: .8em;
    font-weight: 500;
    min-width: 100px;
    padding: 8px 0;
    text-align: center;
    width: 100%;
}

.severity1 {
    background-color: #87cefa;
    color: #555;
}

.severity2,
.likelihood4 {
    background-color: #6495ed;
}

.severity3,
.likelihood3 {
    background-color: #53b353;
}

.healthseverity1 {
    background-color: rgb(255, 220, 105);
    color: #777;
}

.severity4,
.likelihood2,
.healthseverity2 {
    background-color: #fd9d5d;
}

.severity5,
.likelihood1,
.healthseverity3 {
    background-color: #fc5252;
}

.recommendation2 {
    background-color: lightgreen;
    color: #fff;

}

.recommendation3 {
    background-color: rgb(255, 220, 105);
    color: #fff;

}

.recommendation4 {
    background-color: #fd9d5d;
    color: #fff;

}

.recommendation5 {
    background-color: #fc5252;
    color: #fff;
}
.mleft-1{
    margin-left: .5rem;
}
.searchModal{
position: static !important;
}
/* .MuiAutocomplete-option.Mui-focused
   {
      background-color: "#3878b4" !important,
   } */
.mr-1{
    margin-right: 1.5rem;
}
.accordion-button{
    background-color:#f0f0f0;
}
.pl-1{
padding-left: 1rem;
}
.centerFlex{
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.scheck {
    display: flex;
    gap: 6px;
    justify-content: end;
    text-align: center;
    align-items: center;
}
.width-content{
    width:fit-content;
}
.interactTb tr:hover {
    background-color: #efefef;
    cursor: pointer;
}
.trAllergy:hover{
    background-color: #efefef;
    cursor: pointer;
}
.mPadLR {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.MuiChip-label{
    width: 150px;
}
.modalW {
    --bs-modal-width: 60% !important;
}

.modalW .modal-content {
    border-radius: 8px;
    border: 8px solid #0061AA;
}

.modalImgate {
    --bs-modal-width: 90% !important;
    z-index: 5000;
    min-height: 90%;
    min-width: 90%;

}

.searchB {
    border: 1px solid gainsboro;
    padding: 20px 10px 10px 20px;
    background-color: #f0f0f0;
}

.searchB2 {
    border: 1px solid gainsboro;
    padding: 20px 10px 10px 20px;
    background-color: #f0f0f0;
}

.caret {
    right: 8% !important;
    display: block;
    position: relative;
}

.btn-primary {
    /* background-color: #0061AA; */
}

.flex {
    display: flex;
}

.btnFot {
    height: 50px;
    padding: 10px 20px 10px 20px;
    /*font-family: 'Lohit Tamil';*/
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

.logBox {
    position: absolute;
    width: 850px;
    background: #F5F5F5;
    border-radius: 40px;
}

.top-1 {
    top: -0.2vw;
    position: relative;
}

.imageHomeIcon {
    /* position: absolute; */
    width: 100%;
    height: 100%;
}

.signBox {
    padding-top: 5%;
    padding-left: 15%;
}

.signupBox {
    padding-top: 5%;
    /* padding-left: 15%; */
}

.invalidpassMessage {
    border-color: red;
    color: red;
    display: flex !important;
    font-size: 15px;
    font-weight: 500;
    margin-top: 5px;
}

.imageHomeIconLogo {
    width: 85%;
}

.SignTxt {
    font-family: 'InterMedium';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: black;
    margin-top: 36px;
}

.loginlabel {
    font-family: 'InterRegular';
    font-size: 22px;
    font-weight: 600;
    margin-top: 22px;
    color: black;
}

.signtxtbox {
    width: 85%;
    height: 42px;
    border: 1px solid #000000;
    border-radius: 8px;
    font-size: 16px;
    color: black;
    padding-left: 5px;
}

.signBnt {
    background-color: #0061AA !important;
    font-family: InterRegular;
    border-radius: 6px;
    width: 85% !important;
    padding-top: 6px;
    padding-bottom: 6px;
}

.signUpBnt {
    background-color: #0061AA !important;
    font-family: InterRegular;
    border-radius: 6px;
    width: 92.5% !important;
    padding-top: 6px;
    padding-bottom: 6px;
}

.forgotAccount {
    display: flex;
    /* justify-content: center; */
    margin-top: 12px;
    font-size: 14px;
}

.martopup {
    margin-top: 50px;
    font-family: 'InterRegular';
    font-size: 14px;
    color: black;
}

.submit-btn {
    color: #f1efef !important;
}

.ptop {
    margin-top: 25px;
}


.Severe {
    background-color: #fd6565;
    border-color: #fd6565;
}

.Good {
    background-color: #77ba5f;
    border-color: #77ba5f;
}

.ForgotTxt {
    font-family: 'InterMedium';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 48px;
    color: black;
    margin-top: 15px;
}

.forgotlabel {
    font-family: 'InterRegular';
    font-size: 16px;
    font-weight: 600;
    /* margin-top: 22px; */
    color: black;
}

.forgottxtbox {
    width: 85%;
    height: 30px;
    font-size: 16px;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 14px;
    color: black;
}

.signuptxtbox {
    /* width: 85%; */
    height: 30px;
    font-size: 16px;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 14px;
    color: black;

}

.Maderate {
    background-color: orange;
    border-color: orange;
}

.forgotlabel label {
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.Well {
    background-color: dodgerblue;
    border-color: dodgerblue;
}

.back-btn {
    color: #f1efef !important;
    margin-top: 0.5rem;
    background-color: #6c757d !important;
}

.Unlikely {
    background-color: #50e14e;
    border-color: #50e14e;
    color: black;
}

.invalidpass {
    border-color: red !important;
}

.image-containerLoader {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.image-containerLoader img {
    max-width: 100%;
    max-height: 100%;
}

.DurgInteractionsPage {
    padding-top: 20px;
}

.AbbreviatedBox {

    height: 100%;
}

.modalZIndex {
    z-index: 5000;

}

.interModalHeight {
    height: 95vh;
}

.AbbreviatedBoxz {
    height: 87vh;
}

.iframeStyle {
    width: 100%;
    height: 100%;
}

/* .modal-lg{
    --bs-modal-width: 940px;
} */
.boxBody {
    /* height: 96vh; */
    min-height: 96vh;
    max-width: 1500px;
}

.mp120 {
    padding-top: 100px;
}

.pclass {
    background-color: white;
    padding: 12px;
    border-radius: 5px;
    height: 50vh;
    overflow: auto;
}

.custom-class {
    color: red;
}

#Drugname {
    font-size: 20px;
}

#Footer {
    background-color: #0061AA;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    flex: 1 1;
    font-size: .8em;
    padding: 6px 20px;
    width: 100%;
}

#Footer #FooterLeft {
    display: flex;
    flex: 3 1;
    flex-direction: row;
    gap: 30px;
}

#Footer a {
    color: #ddd;
}

a:link {
    text-decoration: none;
}

.forgotinvalidpassMessage {
    border-color: red;
    color: red;
    display: flex !important;
    font-size: 12px;
    font-weight: 500;
    margin-top: 0px;
}

.AlleryCheck {
    padding-top: 20px;
    display: none;
}

.martop {
    margin-top: 10px;
}

.gui {
    width: 168px;
}

#dash-upd-container {
    background-color: #fff;
    border-radius: 20px;
    height: 100%;
    padding: 5px 20px 20px 30px;
    margin-left: 14px;
}

#dash-upd-container .dash-upd-header {
    font-size: 1.2em;
    margin: 10px auto 5px;
    margin-top: 0px;
}

.vstack {
    display: flex;
}

#dash-upd-container .dash-upd-card {
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 10px;
    cursor: pointer;
    display: table;
    padding: .4rem !important;
}

#dash-upd-container .dash-upd-card .dash-upd-img {
    display: table-cell;
}

#dash-upd-container .dash-upd-card .dash-upd-text {
    display: table-cell;
    font-size: 1rem;
    font-weight: 600;
    padding-left: 1rem;
    text-align: left;
    vertical-align: middle;
}

#dash-upd-container .dash-upd-card .dash-upd-count-elite {
    color: #4b006e;
    display: table-cell;
    font-size: 1.2rem;
    font-weight: 700;
    padding-right: 1rem;
    text-align: right;
    vertical-align: middle;
}

#dash-upd-container .dash-upd-card .dash-upd-img img {
    background-color: #dcedff;
    border-radius: 5px;
    height: 37px;
    padding: 4px;
}

#dash-upd-container .dash-upd-card:hover,
#dashboard-main #dash-upd-container .dash-upd-card:hover .dash-upd-img img {
    background-color: #d3d3d3
}

.wit-100 {
    width: 100px;
    /* margin-left: 20px; */
}