#Footer {
    z-index: 1300; /* Same as navbar to ensure it overlaps the drawer */
    position: absolute;
    bottom:0;
    background-color: #0262AB;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    display: flex;
    padding: 6px 20px;
    width: 100%;
    @media (max-width: 767px) {
        flex-direction: column;
        text-align: center;
    }
    @media (max-width: 767px) {
        background-color: #ffffff;
        border-radius: 0;
        border-top: 1px solid #eee;
    }
    #FooterLeft {
        display: flex;
        flex-direction: row;
        gap: 30px;
        font-weight: 600;
        @media (max-width: 767px) {
            justify-content: space-evenly;
            margin-bottom: 8px;
        }
        a {
            color:#ffffff;
            @media (max-width: 767px) {
                color:#0262AB;
                font-size: 12px;
            }
        }
       
    }

    #FooterRight{
       
        @media (max-width: 767px) {
            font-size: 12px;
        }
    }
    a {
        color: #eee;
        
        a:link {
            text-decoration: none;
        }
        @media (max-width: 767px) {
            color: #727272;
        }
    }
    
}

