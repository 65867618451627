@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
#modal{
    //MUI TextField
    .MuiOutlinedInput-root {
        border-radius: 16px;
      }
    .MuiInputLabel-root {
        padding-left: 32px; // Adjust padding to ensure label does not overlap with the search icon
      }
    .MuiInputLabel-shrink {
        padding-left: 0; // Remove padding when the label is floating
      }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000 !important; /* Ensure text color remains unchanged */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background change */
    }
}
#resetPasswordPage {
    font-family: 'Roboto', sans-serif;
    background: #FEFEFE;
    position: relative; /* Ensure the container has a defined position */
    min-height: 100vh; /* Ensure the container covers the entire viewport height */
   
    width: 100%;
    z-index: 1;
    margin-bottom: 28px;

#resetPasswordContainer {
    min-height: 100vh; /* Ensure the container covers the entire viewport height */
    display: flex;
    flex-grow: 1; /* Expand to fill remaining space */
    padding: 80px;
    padding-top: 60px;
    // Add other styles here

    
    img{
        height: 56px;
        margin-bottom: 40px;
    }
    h1{
        font-size: 32px;
    }

    //MUI TextField
    .MuiOutlinedInput-root {
        border-radius: 16px;
      }
    .MuiInputLabel-root {
        padding-left: 32px; // Adjust padding to ensure label does not overlap with the search icon
      }
    .MuiInputLabel-shrink {
        padding-left: 0; // Remove padding when the label is floating
      }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000 !important; /* Ensure text color remains unchanged */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background change */
    }

    .flex-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      #resetPasswordButton{
        padding: 16px 8px;
        border-radius: 16px;
        background-color: #0262AB;
        font-size: 16px;
        margin-top: 32px;
        box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15); 
      }

  }

  
  #illustrationContainer{
    background: linear-gradient(to bottom, #002195, #0262AB, #00A8FF);
    min-height: 100vh; /* Ensure the container covers the entire viewport height */
    display: flex;
    padding: 60px 80px;
    #logo{
      height: 56px;
      margin-bottom: 40px;
  }
   
    
    
    h3{
        color: #ffffff;
        font-size: 28px;
        margin-bottom: 20px;
    }
    .illustration{
      width: 100%;
      max-height: 400px; // Set max height to 360px
      object-fit: contain;
  }
    
    
    #illustrationCaption {
      color: white;
      padding: 0;
      margin-bottom: 40px;
      font-weight: bold;
     
  
  
  }
    
  
 
   
  }
}
