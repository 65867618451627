@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
#dashboard-main {
    /* standard definition (as fallback) */
   
    width: 100%;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    @media (max-width: 767px) {
      
      height: 100vh; 
      height: 100dvh; 
    }
}


#dash-content-main{
    padding: 0px 40px;
    padding-top: 120px;
    position: relative; /* Ensure the container has a defined position */
    z-index: 1;
 
    @media (max-width: 991px) {
      padding: 0;
      padding-top: 100px;
    }
    #headline {
        @media (max-width: 991px) {
          justify-content: center;
          align-items: flex-start;
          margin: 0 12px;
        }
        @media (max-width: 767px) {
          padding: 0;
        }
        #headlineCaption {
          display: flex;
          justify-content: center;
          
          @media (max-width: 991px) {
            margin:0;
          }
          img {
           width: 120px;
           margin-right: 12px;
              @media (max-width: 991px) {
                width: 80px;
                padding: 0;
              }
              @media (max-width: 768px) {
                width: 60px;
              }
          }
      }
  
      #headline-text {
          max-width: fit-content; // Fixed width for the text container
          text-align: center;
          padding:0;
          margin-right:120px;
          @media (max-width: 991px) {
            margin-right: 0px;
          }
          h1 {
            font-size: clamp(20px, 4vw, 32px); // Adjust the font size as needed
              font-weight: bold;
          }
          
          h2 {
              font-size: clamp(16px, 4vw, 28px); // Adjust the font size as needed
          }
  
          h4 {
                font-size: 12px; // Adjust the font size as needed
                font-weight: 300;
                color: #727272;
    
                span {
                    font-family: 'Times New Roman';
                    font-weight: 800;
                    color: #016aaa;
                    font-size: clamp(12px, 4vw, 16px); 
                }
            }
        }
    }
    

    #search {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 996px;
        margin: 0 auto; // Center the container horizontally
        #checkboxContainer{
            margin-bottom: 10px;
            display: flex;
            align-self: flex-end;
            align-items: flex-end;
            .checkbox {
                // Customize checkbox styles
                &.MuiCheckbox-root {
                  border-radius: 8px; // Adjust border radius as needed
                  color: #016aaa; // Change color to your desired color
                }
              }
            
              .label {
                // Customize label styles
                &.MuiFormControlLabel-label {
                  font-weight: lighter;
                  font-size: 12px; // Adjust font size as needed
                  color: #727272;
                }
              }
        }
        
      }
      
      #Menu{
        margin: 40px 0;
        margin-top: 0;
        
        .row-equal-height {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            @media (max-width: 767px) {
              display: flex;
              flex-wrap: nowrap;
              overflow-x: auto;
              -webkit-overflow-scrolling: touch;
              &::-webkit-scrollbar {
                display: none;
              }
            }
            
            .card-custom {
              padding: 20px;
              width: 100%;
              height: 100%;
              border-radius: 32px; // Rounded corners
              display: flex;
              justify-content: center;
              transition: transform 0.2s ease, box-shadow 0.1s ease; 
              border: none;
              box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Slightly increase shadow on hover */
              &:hover {
                  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly increase shadow on hover */
                  transform: scale(1.1);
                  
                  cursor: pointer;
                  .MuiButtonBase-root{
                    border: 1px solid #ffffff; /* Customize border color */
                    background: linear-gradient(to left, #002195, #0262AB, #00A8FF); /* Ensure background is transparent */
                    color: #ffffff; /* Customize icon color */
                  }
                  @media (max-width: 991px) {
                    transform: none;
                  }
              }
              .MuiBadge-badge{
                background: linear-gradient(to left, #002195, #0262AB, #00A8FF);
                margin-top: 20px;
                margin-right: 40px;
                padding: 4px 8px;
                color:#ffffff;
              }
              .card-media {
                display: flex;
                background: rgba(52, 144, 231, 0.1); // Background color for the media section
                border-radius: 20px;
                overflow: hidden; // Ensure media respects rounded corners
                justify-content: center;
                align-self: center;
                width: 100%;
                
                img {
                    padding: 20px;
                  height: 120px; // Set image height
                  width: 100%; // Make image width adjust automatically
                }
    
              }
            
              .card-title {
                color: #0262AB; // Title text color
                font-size: 20px;
                font-weight: bold;
              }
      
              .card-text{
                font-size: 16px;
                color: #727272;
                @media (orientation: portrait) {
                  @media (max-width: 385px) {
                    display: none;
                   }
                 }
               
              }
              .card-body{
                padding: 20px 0;
                @media (orientation: portrait) {
                  @media (max-width: 385px) {
                    padding-bottom: 0px;
                   }
                 }
              }

              .MuiButtonBase-root{
                align-self: center;
                border: 1px solid #0262AB; /* Customize border color */
                border-radius:40px; /* Make the button circular */
                width: 40%;
                background: linear-gradient(to left, #ffffff, #ffffff); // Default background
                color: #0262AB; /* Customize icon color */
              }
            }
          }





        // #MenuItems {
        //     display: flex;
        //     flex-wrap: wrap; /* Allows items to wrap to the next line */
        //     margin-top: 20px; /* Adjust spacing between label and cards */
        //     gap: 20px; /* Adjust the gap between cards */
        //     padding: 0;
        //     .MenuCard {
        //         display: flex;
        //         flex-wrap: wrap;
        //         padding: 0;
        //         border-radius: 32px;
        //         background-color: #ffffff;
        //         width: 100%;
        //         height: 100%;
        //         .card-custom{
        //           .card-body {
        //             /* Styles for the card body */
                  
        //             justify-content: center;
        //             transition: transform 0.2s ease, box-shadow 0.1s ease; 
        //             padding: 20px;
        //             .image-container {
        //                 height: 114px; /* Adjust height as needed */
        //                 overflow: hidden;
        //                 background-color: #E1F4FF;
        //                 border-radius: 12px;
        //                 display: flex;
        //                 flex-direction: column;
        //                 justify-content: center;
        //                 align-items: center;
        //                 margin-bottom: 10px;
        //                 width: 100%;
        //                 img {
                           
        //                     width: auto; /* Allow image to adjust its width */
        //                     max-height: 70%; /* Set max height of image to 80% of container */
        //                     object-fit: contain; /* Maintain aspect ratio while fitting within container */
        //                 }
        //             }

        //             .optional-image-container {
        //                 height: 114px; /* Adjust height as needed */
        //                 overflow: hidden;
        //                 background-color: #E1F4FF;
        //                 border-radius: 12px;
        //                 display: flex;
        //                 padding-left: 25%;
        //                 align-items: center;
        //                 margin-bottom: 10px;
        //                 img {
                           
        //                     width: auto; /* Allow image to adjust its width */
        //                     max-height: 70%; /* Set max height of image to 80% of container */
        //                     object-fit: contain; /* Maintain aspect ratio while fitting within container */
        //                 }
        //                 .optional-chip {
        //                     right:60px;
        //                     top:84px;
        //                     padding: 4px 8px;
        //                     background: linear-gradient(to left, #002195, #0262AB, #00A8FF);
        //                     color: white;
        //                     margin-left: auto;
        //                     align-self: flex-start;
        //                     border-radius: 20px;
        //                     font-size: 10px;
        //                     font-weight: bold;
        //                 }
        //             }
        //             .cardBottom{
        //                 display: flex;
        //                 align-items: flex-start;
        //                 .card-description{
        //                     h5 {
        //                         /* Styles for the card title */
        //                         background: linear-gradient(to left, #002195, #0262AB, #00A8FF);
        //                         -webkit-background-clip: text; /* For WebKit browsers */
        //                         -webkit-text-fill-color: transparent; /* For WebKit browsers */
        //                         background-clip: text; /* For other browsers */
        //                         color: transparent; /* Ensure text color is transparent */
        //                         font-size: 18px;
        //                         font-weight: bold;
        //                         margin-bottom: 10px;
        //                     }
        //                     p{
        //                         font-size: 12px;
        //                         color: #727272;
        //                     }
        //                 }
                     
        //                     .arrow-container {
        //                         margin-left: 10px;
        //                         background-color: #016aaa;
        //                         color: white;
        //                         display: flex;
        //                         justify-content: center;
        //                         align-items: center;
        //                         width: 30px; /* Adjust size of circular container */
        //                         border-radius: 100%;
        //                         cursor: pointer;
        //                     }
                            
                        
                        
        //             }
        //           }
        //         }
                
        //         transition: transform 0.1s ease, box-shadow 0.1s ease; 
        //         &:hover {
        //             box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly increase shadow on hover */
        //             transform: scale(1.05);
        //             cursor: pointer;
        //             border: none;
        //         }
        //     }
        // }

      }
}




