@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

#main-MyAccount {
    width: 100%;
    z-index: 1;
    font-family: 'Roboto', sans-serif;
    background-color: #F4F5FA;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      background-color: #ffffff;
    }
    #content{
      padding: 100px 140px 40px 140px;
      @media (max-width: 991px) {
      padding: 100px 0px 40px 0px;
      }
      #contentCaption{
        text-align: left;
        
        span{
          color: #727272;
        }
      }
      #contentContainer{
        margin-top: 40px;
        background-color: #ffffff;
        padding: 40px;
        border-radius: 40px;
        display: flex;
        @media (max-width: 991px) {
          padding: 12px;
          margin-top: 20px;
        }
        // box-shadow: 0px 8px 24px 0px rgba(22, 22, 22, 0.15); 
        
       #personalInformation{
        padding: 0px 40px;
        .info{
          margin-bottom: 40px;
          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
          #mainInfo{
            @media (max-width: 1160px) {
              display: flex;
              flex-direction: column;
            }
            .extraInfo{
              color: #727272;
  
              svg{
                margin-right: 8px;
              }
            }
          }
          .details{
            .label {
              color: #727272;
              font-size: 16px;
              font-weight: 500;
            }
            h6{
              color: #2c2c2c;
              font-size: 16px;
              line-height: 1.5;
              margin-left: 4px;
              
            }
          }
        }
        
      }
      #subscription{
        border: 1px solid #b8b8b8;
        border-radius: 32px;
        padding: 32px;
        span{
          font-weight: 500;
          color: #727272;
        }
        h6{
          color: #0262AB;
        }
        #subscriptionDate{
          display: flex;
          flex-direction: row;
        }
        .MuiAlert-colorInfo{
          border-radius: 12px;
        }
        #imgateway{
          align-items: flex-start;
          .validIcon{
            margin-left: 8px;
            height: 20px;
          }
        }
        #purchaseButton{
          padding: 16px 8px;
          border-radius: 16px;
          background: linear-gradient(to right, #002195, #0262AB, #00A8FF); // Linear gradient background
          font-size: 16px;
          margin-top: 32px;
          width: 100%;
          box-shadow: 0px 8px 24px 0px rgba(0, 89, 255, 0.308); 
          svg{
            color: #ffffff
          }
        }
      }
    }
  }
}