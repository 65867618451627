// Define variables
$navbar-bg-color: #ffffff;
$text-color: white;
$border-radius: 15px;
$padding: 1rem;
$margin-right: 1rem;

// Define mixins if needed
@mixin border-radius($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

#banner {
  z-index: 1300;
  position: fixed;
  color: $text-color;
  padding: 0;
  width: 100%;
  @include border-radius($border-radius);
  height: 68px;
  background-color: #ffffff;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;


#logo-img {
  width: 140px; /* Ensures the image does not exceed the width of its container */
  height: 100%;
  height: auto; /* Allows the image height to adjust proportionally */
  display: block; /* Ensures the image behaves as a block-level element */
  @media (max-width: 767px) {
    width: 120px
  }
  @media (max-width: 347px) {
    width: 100px
  }
}

#signupButton{
  padding: 8px 16px;
  border-radius: 32px;
  background-color: #0262AB;
  font-size: 16px;
  text-transform: none;
  box-shadow: 0;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

#signinButton{
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 16px;
  text-transform: none;
  @media (max-width: 767px) {
    font-size: 12px;
  }
  
}

}
#landingPage{
  font-family: 'Roboto', sans-serif;
  @media (max-width: 767px) {
  overflow-x: hidden;
  }
  #hero{
    padding-top: 120px;
    background: linear-gradient(to bottom, #002195, #0262AB, #00A8FF);
    padding-bottom: 320px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 991px) {
      flex-direction: column-reverse; // Reverse the order of columns on mobile screens
      padding-bottom: 160px;
    }
    #heroCaption{
      padding: 0;
      padding-left: 80px;
      
      h1{
        color:#ffffff;
        font-size: clamp(20px, 4vw, 40px);
      }
      h2{
        font-weight: 300;
        color: #ffffff;
        margin-bottom: 40px;
        font-size: clamp(16px, 4vw, 32px);
      }
      h4 {
        // Adjust the font size as needed
        font-weight: 300;
        color: #ffffff;
        font-size: clamp(12px, 4vw, 24px);

        span {
            font-family: 'Times New Roman';
            font-weight: 800;
        }
    }
      #signupButton{
        padding: 16px 32px;
        border-radius: 32px;
        border-color: #ffffff;
        color: #ffffff;
        margin-top: 16px;
        box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.308); 
        font-size: 16px;
          
        &:hover { 
          box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15);
          background-color: #ffffff; // Hover background color
          color: #0262AB; // Text color on hover
          border-color: #0262AB; // Border color on hover },
        }
        @media (max-width: 767px) {
          // Styles for screens smaller than 767px
          font-size: 12px;
        }
      }

      @media (max-width: 991px) {
        // Styles for screens smaller than 767px
        padding: 40px;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
      }
    }

    #heroBanner{
      padding:16px;
      @media (max-width: 991px) {
        // Styles for screens smaller than 767px
        display: block; // Adjust as needed for small screens
        padding: 20px;
        margin-bottom: 20px;
      }
    }
  }
  
  #discover{
    position: relative;
    margin-top: -268px;
    margin-bottom: 80px;
    @media (max-width: 991px) {
      // Styles for screens smaller than 767px
      display: block; // Adjust as needed for small screens
      padding: 0;
      margin-top: -92px;
    }
  
    h1{
      font-weight: bold;
    }
    #videoContainer{
      padding: 0;
      .react-player-container {
      position: relative;
      width: 80%;
      max-width: 1000px;
      margin: auto;
      border-radius: 40px; // Rounded corners
      overflow: hidden; // Ensures child elements respect the border-radius
      box-shadow: 0px 8px 24px 0px rgba(22, 22, 22, 0.15); 
      &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly increase shadow on hover */
        transform: scale(1.05);
    }
    
      .aspect-ratio-container {
        padding-top: 56.25%; // 16:9 Aspect Ratio
        position: relative;
        border-radius: 40px; // Rounded corners
        overflow: hidden; // Ensure ReactPlayer respects border-radius
    
        .react-player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 40px; // Ensure ReactPlayer respects rounded corners

          .react-player__shadow {
            background: #016aaa !important; /* Change background color */
            transition: width 0.1s ease, height 0.1s ease !important;
            box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1) !important;  
           
          }
        }
      }
      
      transition: transform 0.2s ease, box-shadow 0.1s ease; 
      &:hover { 
        .react-player__shadow {
        width: 72px !important; /* Adjust size */
        height: 72px !important; /* Adjust size */
         
        }
      }
    }
    }
    
    
  }

  #features{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; // Centers items horizontally
    padding: 40px 68px;
    @media (max-width: 991px) {
      padding: 40px 20px;
    }
    @media (max-width: 767px) {
      padding: 0;
      margin-bottom: 40px;
    }
  }
    .row-equal-height {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      @media (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
        
      }
      .card-custom {
        width: 100%;
        height: 100%;
        border-radius: 32px; // Rounded corners
        display: flex;
        justify-content: center;
        transition: transform 0.2s ease, box-shadow 0.1s ease; 
        &:hover {
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Slightly increase shadow on hover */
            transform: scale(1.1);
            @media (max-width: 767px) {
              transform: none;
            }
        }
        .card-media {
          padding: 40px;
          background: linear-gradient(to bottom, #00509b, #0267ab, #00A8FF);  // Background color for the media section
          border-top-left-radius: 32px;
          border-top-right-radius: 32px;
          overflow: hidden; // Ensure media respects rounded corners
          width: 100%;
          
      
          img {
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
            height: 120px; // Set image height
            width: 100%; // Make image width adjust automatically
          }

          video{
            width: 100%;
            height: 100px;
          }
        }

        .card-media-gif {
          padding: 40px;
          padding-bottom: 0;
          background-color: #ffffff;  // Background color for the media section
          border-top-left-radius: 32px;
          border-top-right-radius: 32px;
          overflow: hidden; // Ensure media respects rounded corners
          width: 100%;
          
          video{
            width: 100%;
            height: 260px;
          }
        }
      
        .card-title {
          color: #0262AB; // Title text color
          font-size: 20px;
          font-weight: bold;
        }

        .card-text{
          font-size: 16px;
          color: #727272;
        }
        .card-body{
          padding:32px;
        }

        .play-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          cursor: pointer;
          transition: transform 0.3s ease; /* Ensure smooth transition */
          
          .iconBg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%; // Same size as the parent
            height: 100%; // Same size as the parent
            background-color: #ffffff; // White background
            border-radius: 50%; // Makes it a circle
            z-index: -1; // Ensure it goes behind the icon
          }
        
          svg {
            position: relative; // Ensures the icon stays above the background
          }
        }
       
        .image-overlay-container {
          position: relative;
          padding: 32px; // Padding applied to the container
          overflow: hidden; // Clips the overlay and image to fit the rounded corners
          .media-container {
            position: relative;
            width: 100%;
            height: 100%;
            
            .card-thumbnail {
              width: 100%;
              height: auto;
              display: block;
              transition: opacity 0.3s ease;
              border-radius: 24px;
            }
        
            .video-preview {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              opacity: 0; /* Initially hidden */
              pointer-events: none; /* Prevent interaction */
              transition: opacity 0.3s ease;
              border-radius: 24px;
            }
          }
        
        
        
          .color-overlay {
            position: absolute;
            top: 32px; // Match padding value
            left: 32px; // Match padding value
            width: calc(100% - 64px); // Subtract padding on both sides
            height: calc(100% - 64px); // Subtract padding on both sides
            background-color: rgba(0, 0, 0, 0.068); // Adjust color and opacity
            pointer-events: none;
            opacity: 1;
            transition: opacity 0.3s ease;
            border-radius: 24px;
            &:hover {
              opacity: 0.7;
            }
          }
        }
        
      }
    }

    
 

  .sectionCaption{
    text-align: center;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    h4{
      color:#202020;
      font-size: clamp(12px, 4vw, 24px);
    }
    h1{
      background: linear-gradient(to right, #002195, #0262AB, #00A8FF);
      -webkit-background-clip: text; /* For WebKit browsers */
      -webkit-text-fill-color: transparent; /* For WebKit browsers */
      background-clip: text; /* For other browsers */
      color: transparent; /* Ensure text color is transparent */
      font-weight: bolder;
      font-size: clamp(20px, 4vw, 40px);
    }
  }
  #guides {
    display: flex;
    padding: 40px 68px;
    margin-bottom: 40px;
  
    @media (max-width: 991px) {
      padding: 40px 52px;
    }
  
    @media (max-width: 767px) {
      padding: 0;
    }
  
    #videos {
      @media (max-width: 767px) {
        margin-bottom: 100px;
      }

      #cardVideo{

        &.active {
          background-color: #0262AB;
          color: #ffffff;
        }
      
        &:hover {
          background-color: #0262AB;
          color: #ffffff;
        
            .card-thumbnail {
              opacity: 0; /* Hide thumbnail on hover */
            }
        
            .video-preview {
              opacity: 1; /* Show video preview on hover */
            }

        }
      }
    }
  }
  
  #contactInfo {
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
    .contact-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: #727272;
      svg {
        margin-right: 8px;
      }

      span{
        font-size: large;
      }
    }
  }

}
