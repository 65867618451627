@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

#modal{
    //MUI TextField
    .MuiOutlinedInput-root {
        border-radius: 16px;
        @media (max-width: 991px) {
          height: 68px;
        }

        @media (max-height: 952px) {
          height: 56px;
        }
      }
    .MuiInputLabel-root {
        padding-left: 32px;
        @media (max-width: 991px) {
          // Styles for screens smaller than 767px
          padding-top: 8px;
        }
        @media (max-height: 952px) {
          padding-top: 0;
        } // Adjust padding to ensure label does not overlap with the search icon
      }
    .MuiInputLabel-shrink {
        padding-left: 0; // Remove padding when the label is floating
      }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000 !important; /* Ensure text color remains unchanged */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background change */
    }

    #forgotPassword{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 480px;
    width: 100%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    text-align: center;
    padding: 40px;
    border-radius: 40px;
      @media (max-width: 991px) {
        max-width: 360px;
      }
    }
}

#loginPage {
 
    font-family: 'Roboto', sans-serif;
    background: #FEFEFE;
    position: relative; /* Ensure the container has a defined position */
/* dynamic vh will make the trick on iOS *//* Ensure the container covers the entire viewport height */
    z-index: 1;
   
#loginContainer {
     /* Ensure the container covers the entire viewport height */
    display: flex;
    padding: 60px 80px;
    // Add other styles here
    @media (max-width: 991px) {
      // Styles for screens smaller than 767px
      justify-content: space-between;
      padding: 20px;
      padding-top: 60px;
    }
    #loginForm{
      width: 100%;
      display: flex;
      flex-direction: column;
    img{
        height: 56px;
        margin-bottom: 40px;
        @media (max-width: 991px) {
          align-self: center;
          height: 80px;
          margin-bottom: 60px;
        }
    }
    h1{
      font-size: clamp(20px, 4vw, 40px);
        margin-bottom: 20px;
    }

    
    //MUI TextField
    .MuiOutlinedInput-root {
        border-radius: 16px;
        @media (max-width: 991px) {
          height: 68px;
        }

        @media (max-height: 952px) {
          height: 56px;
        }
      }
    .MuiInputLabel-root {
        padding-left: 32px; 
        @media (max-width: 991px) {
          // Styles for screens smaller than 767px
          padding-top: 8px;
        }
        @media (max-height: 952px) {
          padding-top: 0;
        }// Adjust padding to ensure label does not overlap with the search icon
      }
    .MuiInputLabel-shrink {
        padding-left: 0; // Remove padding when the label is floating
      }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: #000 !important; /* Ensure text color remains unchanged */
    transition: background-color 5000s ease-in-out 0s; /* Prevent background change */
    }

      #loginButton{
        padding: 16px 8px;
        border-radius: 16px;
        background-color: #0262AB;
        font-size: 16px;
        margin-top: 32px;
        margin-bottom: 20px;
        width: 100%;
        box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15); 
      }
      #AthensButton{
        padding: 16px 8px;
        color: #2c2c2c;
        border-radius: 16px;
        font-size: 16px;
        margin-top: 20px;
        width: 100%;
        border-color: #d1d1d1;
        &:hover {
          border-color: #0262AB;
        }
      }
    }

      #signupLink {
        margin-bottom: 16px;
        margin-top: 40px;
        text-align: center;
        @media (max-width: 767px) {
          margin-top: 20px;
          margin-bottom: 32px;
        }
        #signupText{
            font-weight: bold;
            text-decoration: underline;
            color: #0262AB;
        }
      }

  }

  
  #carouselContainer{
    background: linear-gradient(to bottom, #002195, #0262AB, #00A8FF);
    min-height: 100vh; /* Ensure the container covers the entire viewport height */
    display: flex;
    padding: 60px 80px;
    
   
    
    
    h1{
        color: #ffffff;
        font-weight: bolder;
        font-size: 28px;
    }
    // .carousel{
    //     display: flex;
    //     height: 100%;
    // .illustration{
    //     margin-top: 80px;
    //     height: 100%;
    //     max-height: 240px; // Set max height to 360px
    //     object-fit: contain;
    //     margin-bottom: 240px;
    // }

    // .carousel-caption {
    //     color: white;
    //     padding: 0;
    //     text-align: justify;
    //     height: 160px;
    //     margin-bottom: 20px;

    //     h3 {
    //         text-align: center;
    //         margin: 0;
    //         font-size: 20px;
    //         font-weight: bold;
    //     }

    //     p {
    //         font-size: 16px;
    //         margin: 12px 0 0;
    //     }
    
    
    // }
    // .control-dots {
    //     position: absolute;
    //     width: 100%;
    //     text-align: center;
    //     bottom: 0;
    //     .dot {
    //         width: 12px;
    //         height: 12px;
    //         border-radius: 50%;
    //         background: #fff;
    //         box-shadow: 0px 8px 24px 0px rgba(22, 22, 22, 0.15); 
    //         margin: 0 5px;
    //         display: inline-block;
    //     }

    //     .dot.selected {
    //         background: #ffffff;
    //         width: 16px;
    //         height: 16px;
    //     }}
 
    // }


    .carousel {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin-top: 20px;
      
      .carouselItem {
        width: 100%;
        border-radius: 20px; // Rounded corners
        display: flex;
        flex-direction: column;
        margin-bottom: 240px;
        flex-wrap: wrap;
        img {
          max-height: 340px; // Set image height
          width: 100%;
          padding: 20px;
          @media (max-width: 1098px) {
            height: 262px;
            margin-bottom: 40px;
          }
          
        }
        .carousel-caption {
              color: white;
              padding: 0;
              text-align: justify;
              margin-bottom: 80px;
              height: 132px;
              @media (max-width: 1098px) {
                // Styles for screens smaller than 767px
                margin-bottom: 20px;
                height: 240px;
              }
              h3 {
                  text-align: center;
                  margin: 0;
                  font-size: 20px;
                  font-weight: bold;
              }
      
              p {
                  font-size: 16px;
                  margin: 12px 0 0;
              }
            }
      }
   
      .control-dots {
            position: absolute;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            .dot {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #fff;
                box-shadow: 0px 8px 24px 0px rgba(22, 22, 22, 0.15); 
                margin: 0 5px;
            }
    
            .dot.selected {
              
                background: #ffffff;
                width: 16px;
                height: 16px;
            }
      }
    }
  }
}
