.hints-container {
    border-bottom: 1px solid lightgray;
    padding-bottom: 25px;
    display: block;
    .label {
        font-weight: normal;
        display: flex;
        gap:15px;
        font-size: .9em;
        .hints {
            font-weight: normal;
            color: #0262AB;
        }
    }
   
}