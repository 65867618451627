@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

#searchContainer {
        padding: 0;
    .MuiAutocomplete-inputRoot {
        border-radius: 40px; // Adjust border radius as needed
        position: relative; // Ensure correct positioning
        background-color: #ffffff;
      }
    //   .MuiInputBase-root.Mui-focused  {
    //     /* Ensure the position or layout properties are stable */
     
    //   }

    .MuiInputLabel-root {
        padding-left: 44px; 
        padding-top: 1px;// Adjust padding to ensure label does not overlap with the search icon
        font-size: clamp(12px, 4vw, 16px);
        font-weight: 400;
      }
      .MuiInputLabel-shrink {
        padding-left: 0;
        padding-top: 0; // Remove padding when the label is floating
        @media (max-width: 767px) {
          padding-left: 8px;
          }
      }
    
      .MuiOutlinedInput-root {
        
    
        fieldset {
          legend {
            width: auto !important; // Ensure legend (gap for label) is properly sized
          }
        }
      }
     
    
     
    .searchButton {
        background: linear-gradient(to right, #002195, #0262AB, #00A8FF); // Linear gradient background
        color: #ffffff; // Button text color
        border: none;
        border-radius: 40px; // 12px radius
        padding: 8px 16px; // Adjust padding as needed
        @media (max-width: 767px) {
          padding: 8px;
          }
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px; // Adjust spacing between button and input
      }
    
      .searchButton:hover {
        background: linear-gradient(to right, #001a7f, #01458c, #0080cc);
      }
    
  }