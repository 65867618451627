*{
    margin: 0px;
    padding: 0;
   
}

.area{
    background-color: #FEFEFE; 
    width: 100%;
    height: 100vh;  /* standard definition (as fallback) */
    height: 100dvh; /* dynamic vh will make the trick on iOS */
  
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.circles {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    background: rgba(52, 144, 231, 0.1);
    clip-path: polygon(
        25% 0%,     /* Top-left */
        75% 0%,     /* Top-right */
        100% 50%,   /* Right-middle */
        75% 100%,   /* Bottom-right */
        25% 100%,   /* Bottom-left */
        0% 50%
    );  /* Symmetrical hexagon shape */
     /* Hexagon shape */
    animation: animate 25s linear infinite;
    bottom: -150px;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}

.circles li:nth-child(1) {
    left: 25%;
    width: 90px;
    height: 80px; /* Adjust size as needed */
    animation-duration: 24s;
    animation-delay: 0s;
}

.circles li:nth-child(2) {
    left: 10%;
    width: 50px;
    height: 40px; /* Adjust size as needed */
    animation-duration: 18s;
    animation-delay: 2s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 70px;
    height: 60px; /* Adjust size as needed */
    animation-duration: 16s;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 50px; /* Adjust size as needed */
    animation-duration: 22s;
    animation-delay: 0s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 100px;
    height: 90px; /* Adjust size as needed */
    animation-duration: 20s;
    animation-delay: 5s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 60px;
    height: 50px; /* Adjust size as needed */
    animation-duration: 24s;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 50px;
    height: 40px; /* Adjust size as needed */
    animation-duration: 17s;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 55px;
    height: 45px; /* Adjust size as needed */
    animation-duration: 15s;
    animation-delay: 2s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 45px;
    height: 35px; /* Adjust size as needed */
    animation-duration: 18s;
    animation-delay: 6s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 110px;
    height: 100px; /* Adjust size as needed */
    animation-duration: 22s;
    animation-delay: 1s;
}
