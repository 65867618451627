$drawer-width: 264px;
$mini-drawer-width: 100px;
$navbar-height: 75px; // Adjust based on the height of your navbar
 .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); // Adjust the color and opacity as needed
    z-index: 1100; // Ensure it covers the main content but is below the drawer
  }
  #sidebar {
    font-family: 'Roboto', serif;
    display: flex;
    align-items: center;
    height: 100vh;
    position: fixed;
    z-index: 1200;
    
    .MuiDrawer-root {
      display: flex;
      align-self: start;
      margin-top: 60px;
    }

    .MuiDrawer-paper {
      height: fit-content; // Fixed height for the drawer paper
      position: relative;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      box-shadow: 0px 8px 24px 0px rgba(30, 116, 185, 0.185); 
      border:none;
      padding: 8px 12px;
      overflow: hidden;
      width: 108px;
      transition: width 0.2s ease; 
    }
   
    
    .drawer {

      // Toggle Button
      #toggleButton{
        padding: 0 8px;
        div{
          display: flex;
        }
      }

      // Items
      .buttonContainer{
        display: flex;
        align-items: flex-start;
        &:hover{
          cursor: pointer;
          .item{
            background: rgba(52, 144, 231, 0.1);
          }
        }

        .item {
        margin: 3px 0;
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        justify-content: center; /* Center items vertically */
        border-radius: 10px;
        overflow: visible;
        width: 100%;
        padding: 4px 16px;

        span {
          color: #0262AB;
          font-size: 12px;
          font-weight: 500;
          text-align: center; /* Center text horizontally */
        }

        .MuiListItemIcon-root {
          padding: 4px;
          justify-content: center;
        }
        img{
          height: 24px;
        }

        // Active Item

        &.active{
          background: linear-gradient(to bottom,  #036fc2, #1d71b1,);
          box-shadow: 4px 0px 4px 0px rgba(30, 116, 185, 0.185); 
          span {
            color: #ffffff;
            font-weight: bolder;
          }
        }
      }}
      

      // When drawer is opened
      &.open{
        .MuiDrawer-paper {
          transition: width 0.2s;
          width: 284px;
          
        }

        #toggleButton{
          justify-content: space-between;
          padding-left: 24px;
          
          div{
            font-size: 16px;
            color: #1976d2;
            font-weight: 500;
            align-items: center;
            display: flex;
          }
          &.MuiButtonBase-root{
            background-color: red;
          }
        }

        .buttonContainer{
          .item {
            margin: 13px 0px;
            width: 100%;
            display: flex;
            flex-direction: row; /* Stack items vertically */
            border-radius: 12px;
            padding: 8px 16px;
            span {
              text-align: left; /* Center text horizontally */
              text-wrap: nowrap;
            }
            img{
              height: 32px;
            }
    
          }
        }
        
      }

      
    }
  }


.dialog-top-center {
  display: flex !important; 
  align-items: flex-start !important;  // Align at the start (top)
  justify-content: center !important;  // Center horizontally
  height: 100%;  // Full height to center horizontally correctly
}

.searchModal {
  width: 942px !important; /* Set width */
  max-width: 942px !important; /* Ensure it doesn't grow beyond this width */
  border-radius: 40px !important;
  padding: 20px !important;
  #headline-container{
    display: flex; /* Align items to the left */
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

        img { max-height:80px;
            height:auto;
            padding-right: 40px;
        }

        #headline-text{
            padding-right: 150px;
            position: relative;
            display: inline-block; 
            /* Center text inside items */
            text-align: center; 
            h1 {
            font-size: clamp(16px, 4vw, 28px); /* Adjust the font size as needed */
            margin-bottom: 12px;
            font-weight: bold;
            color:#0262AB;
            
            }
            
            h5{
              margin-bottom: 0;
              color:#727272;
            }
            span {
              padding-top: 0;
              margin-top: 0;
              color:#727272;
                position: relative;
                font-size: clamp(12px, 3.5vw, 16px); /* Adjust the font size as needed */
                display: inline-block;  
            }
        }
}  

#search {
  display: flex;
  flex-direction: column;
  justify-content: center;
 
  #checkboxContainer{
      margin-bottom: 10px;
      display: flex;
      align-self: flex-end;
      align-items: flex-end;
      .checkbox {
          // Customize checkbox styles
          &.MuiCheckbox-root {
            border-radius: 8px; // Adjust border radius as needed
            color: #016aaa; // Change color to your desired color
          }
        }
      
        .label {
          // Customize label styles
          &.MuiFormControlLabel-label {
            font-weight: lighter;
            font-size: 12px; // Adjust font size as needed
            color: #727272;
          }
        }
  }

  
  
}
}
