// Define variables
$navbar-bg-color: #333;
$text-color: white;
$border-radius: 15px;
$padding: 1rem;
$margin-right: 1rem;

// Define mixins if needed
@mixin border-radius($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

#navBanner {
  z-index: 1300;
  position: fixed;
  color: $text-color;
  padding:0;
  width: 100%;
  @include border-radius($border-radius);
  height: 68px;
  display: flex;
  // background: linear-gradient(to top, #00A8FF, #0262AB, #002195);
  background: linear-gradient(to bottom, #0262AB, #002195);
  box-shadow: none; 
  justify-content: flex-start;
  overflow: visible;
  .MuiToolbar-root{
    align-items: flex-start;
  }
  #feedbackButton{
    padding: 8px 16px;
    border-radius: 32px;
    border-color: #ffffff;
    color: #ffffff;
    align-self: center;
    font-size: 12px;
    margin-right: 20px;
    &:hover {   box-shadow: 0px 8px 24px 0px rgba(0, 153, 255, 0.15);
      background-color: #ffffff; // Hover background color
      color: #0262AB; // Text color on hover
      border-color: #0262AB; // Border color on hover },
    }
  }

  #logo-img {
    
    width: 140px; /* Ensures the image does not exceed the width of its container */
  height: 100%;
  height: auto; /* Allows the image height to adjust proportionally */
  display: block; /* Ensures the image behaves as a block-level element */
  }

  
  #accountButton{
    margin-top: 4px;
    border-radius: 20px;
    text-transform: none;
    padding: 12px 16px;
    span{
    display: flex;
    align-items: center;
    
    svg:first-of-type{
      margin-right: 8px;
    }
    svg:last-of-type{
      margin-right: 0;
      margin-left: 8px;
    }
  }
}
#avatarButton {
  align-self: center;
  
    .MuiAvatar-root{
    background-color: white;
    color: #0262AB;
    width: 40px; // Adjust size as needed
    height: 40px; // Adjust size as needed
    font-size: 1.5rem; // Adjust font size as needed
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }
}

  
// .list{
//   background-color: transparent;
//   padding: 0;
//   margin-top: 0;
//   border-radius: 20px;
//   transition: all 0.3s ease;
//   .MuiListItem-root{
//     border-radius: 20px;
//     padding: 0;
//    }
//    .MuiButtonBase-root:first-of-type{
//     border-radius: 12px;
//     padding: 4px 16px;
//     transition: all 0.3s ease;
//    }
//    .MuiDivider-root{
//     margin:0;
//    }
//    .itemList{
//     padding: 0 8px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0;
//    }
//    .itemList:nth-of-type(1){
//     padding: 8px;
//     padding-bottom: 0;
//     color: #202020;
//   }
//    .itemList:last-of-type{
//     padding: 16px 8px;
//     color: #ffffff;
//     svg{
//       color: #ffffff;
//     }}
//    // When opened
//    &.open{
//     background-color: #ffffff;
//     color: #202020;
//     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
//     margin-top: 8px;
    
//      .MuiButtonBase-root{
//        padding: 16px;
//       }
//       .itemList:nth-of-type(1){
//         padding: 8px;
//         padding-bottom: 0;
//         .MuiButtonBase-root{  
//           padding: 12px;
//           margin: 0px 4px;
//         }

//       }
//       .itemList:last-of-type{
//         margin-top: 8px;
//         padding: 16px 8px;
//         padding-top: 0;
//         color: #ffffff;
//         svg{
//           color: #ffffff;
//         }
//       .MuiButtonBase-root{
        
//         border-radius: 12px;
//         padding: 12px;
//         background-color: #f04f54;
//         margin: 0px 4px;
//         &:hover{
//           background-color: #e7434a !important;
//         }
//        }
      
//       }
//    }
// }



}
#bannerDrawerMobile{
 .MuiIconButton-root{
    z-index: 2000;
  }
  & .MuiDrawer-paper{
   height: 100vh;
   height: 100dvh;
   width: 100dvw;
   background-color: #fefefe;
  }
  
  .MuiList-root .subList{
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Slightly increase shadow on hover */
    
    .MuiListSubheader-root {
      background-color: #ffffff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .MuiButtonBase-root {
      border-radius: 12px;
      margin: 0 8px;
    }
    
  }
  .MuiListItem-root:nth-child(7) {
    background-color: #f04f54 !important;
    border-radius: 12px;
        color: #ffffff;
          svg{
            color: #ffffff;
          }
  
          &:hover{
            background-color: #e7434a !important;
          }
  }
  .MuiListItem-root:nth-child(8)
  {
    color: #727272;
    margin-top: 20px;
    text-align: center;
  }

  

 }
#bannerMenu{
  .MuiMenu-paper {
    background-color: #ffffff !important; 
    padding: 16px 12px!important;
    padding-top: 0 !important; // Example: Change background color
    border-radius: 20px !important;
  
  
    .MuiMenu-list{
      padding: 4px !important;
      border-radius: 12px;
      // Full name
      .MuiMenuItem-root:first-of-type{ 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-weight: bold !important;
        color: #0262AB !important;
        cursor: default !important;
        padding-left: 8px;
        padding-top: 4px;
        span{
          color: #727272;
          font-weight: 300;
          font-size: 12px;
        }
  
        &:hover {
          background-color: #ffffff !important;
        }
      }
      .MuiMenuItem-root:last-of-type{ 
        background-color: #f04f54 !important;
        color: #ffffff;
          svg{
            color: #ffffff;
          }
  
          &:hover{
            background-color: #e7434a !important;
          }
        
      }
      .MuiMenuItem-root{
        margin-top: 4px !important;
        padding: 12px !important;
        border-radius: 12px !important;
        &:hover {
          background-color: #d1d1d1ee !important;
        }
      
        
      }
    }
  }
}
  